// Farm.js
import React from 'react';

const Farm = () => {
  return (
    <div className="page">
      <h2>Farm Page</h2>
      <p>This is the Farm page where users can cultivate crypto assets.</p>
    </div>
  );
};

export default Farm;
