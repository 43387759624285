// Friends.js
import React from 'react';

const Friends = () => {
  return (
    <div className="page">
      <h2>Friends Page</h2>
      <p>This is the Friends page where users can connect and share.</p>
    </div>
  );
};

export default Friends;
