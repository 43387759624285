// Earn.js
import React from 'react';

const Earn = () => {
  return (
    <div className="page">
      <h2>Earn Page</h2>
      <p>This is the Earn page where users can accumulate crypto rewards.</p>
    </div>
  );
};

export default Earn;
