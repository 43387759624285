// App.js
import React, { useState } from 'react';
import Click from './Click';
import Farm from './Farm';
import Earn from './Earn';
import Friends from './Friends';

const App = () => {
  const [currentPage, setCurrentPage] = useState('click');

  const renderPage = () => {
    switch (currentPage) {
      case 'click':
        return <Click />;
      case 'farm':
        return <Farm />;
      case 'earn':
        return <Earn />;
      case 'friends':
        return <Friends />;
      default:
        return <Click />;
    }
  };

  return (
    <div className="app-container">
      <nav>
        <ul>
          <li onClick={() => setCurrentPage('click')}>Click</li>
          <li onClick={() => setCurrentPage('farm')}>Farm</li>
          <li onClick={() => setCurrentPage('earn')}>Earn</li>
          <li onClick={() => setCurrentPage('friends')}>Friends</li>
        </ul>
      </nav>
      <div className="page-content">
        {renderPage()}
      </div>
    </div>
  );
};

export default App;
